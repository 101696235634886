<template>
  <div style="width: 100%;">
    
    <div>
      <el-form :inline="true" class="demo-form-inline" style="color:red;">
          <el-form-item label="">
            Document version in File Library：Rev{{baseInfo.form.libraryRevNumber}}
          </el-form-item>
          <el-form-item label="" style="margin-left:50px;">
            Uploaded Date：{{baseInfo.form.libraryCreateTime}}
          </el-form-item>
      </el-form>
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <div v-for="(item, index) in baseInfo.noteTableData" :key="index">
        <el-form-item label="Rev">
          {{index+1}}
        </el-form-item>
        <el-form-item label="RevisionDate">
          <el-input
            v-model="item.revisionDate"
            placeholder="RevisionDate"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="RequestedBy">
          <el-input
            v-model="item.requestedBy"
            placeholder="RequestedBy"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="Revised Notes">
          <el-input
            v-model="item.itemsRevised"
            placeholder="Revised Notes"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="EditedBy">
          <el-input
            v-model="item.editedBy"
            placeholder="EditedBy"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="Reason">
          <el-input
            v-model="item.reason"
            placeholder="Reason"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="ApprovedBy">
          <el-input
            v-model="item.approvedBy"
            placeholder=""
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item style="width: 120px">
          <el-button
            type="text"
            @click="addNoteTableClick(index)"
            v-if="
              item.revisionDate &&
                item.requestedBy &&
                item.itemsRevised &&
                item.editedBy &&
                item.reason &&
                item.approvedBy
            "
            >Complete</el-button
          >
          &nbsp;&nbsp;
          <el-button
            type="text"
            v-if="!item.isComplete && baseInfo.noteTableData.length > 1"
            @click="removeNoteTableClick(index)"
          >
            <i style="color:Red;font-size:18px;" class="el-icon-delete"></i>
          </el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  props: { baseInfo: {} },
  data() {
    return {
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          reason: "",
          approvedBy: "",
          isComplete: false
        }
      ]
    };
  },
  methods: {
    addNoteTableClick(nowIndex) {
      this.baseInfo.noteTableData.forEach((item, index) => {
        if (index == nowIndex) {
          item.isComplete = true;
        }
      });
      console.log('this.baseInfo.addRevNumber==='+this.baseInfo.addRevNumber)
      if(this.baseInfo.addRevNumber){
        let revNumber = 0
        this.baseInfo.noteTableData.forEach((item)=>{
          if(item.isComplete == true){
            revNumber++
          }
        })
        this.baseInfo.form.revNumber=revNumber+1
        this.$parent.fatherMethod(revNumber);
      }
      this.baseInfo.noteTableData.push({
        revisionDate: "",
        requestedBy: "",
        itemsRevised: "",
        editedBy: "",
        approvedBy: "",
        reason: "",
        isComplete: false
      });
    },
    removeNoteTableClick(idx) {
      this.baseInfo.noteTableData.splice(idx, 1);
    }
  },
  created() {},
  mounted() {}
};
</script>
